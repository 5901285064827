<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept type="search" name="deptCd" :plantCd="plantCd" v-model="searchParam.deptCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="사용자"
            name="userName"
            v-model="searchParam.userName">
          </c-text>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-12">
        <c-table
          ref="userTable"
          title="사용자 목록"
          :columns="grid.columns"
          :data="grid.data"
          :gridHeight="grid.height"
          :selection="popupParam.type"
          :isExcelDown="false"
          :isFullScreen="false"
          :columnSetting="false"
          rowKey="userId"
          @rowDblclick="rowDblclick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="선택" icon="check" @btnClicked="select" />
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'user-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: true,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '이름',
            align: 'center',
            sortable: true
          },
        ],
        data: [],
        height: '500px'
      },
      searchParam: {
        plantCd: null,
        deptCd: null,
        userName: '',
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    plantCd() {
      return this.searchParam.plantCd || this.popupParam.plantCd
    }
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sys.push.tokenUser.list.url;
      // code setting
      // list setting
      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd;
      }
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['userTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
    select2() {
      let selectData = this.grid2.data;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    selectAdd() {
      this.$refs['userTable2'].selected = [];
      let selectData = this.$refs["userTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } 
      this.$_.forEach(selectData, (item) => {
        if (this.$_.findIndex(this.grid2.data, { userId: item.userId }) === -1) {
          this.grid2.data.push({
            userId: item.userId,
            userName: item.userName,
            deptCd: item.deptCd,
            deptName: item.deptName,
            plantCd: item.plantCd,
            plantName: item.plantName,
            jobName: item.jobName,
            spotName: item.spotName,
          })
        }
      })
      this.$refs['userTable'].selected = [];
    },
    selectDelete() {
      this.$refs['userTable'].selected = [];
      let selectData = this.$refs["userTable2"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } 
      this.$_.forEach(selectData, (item) => {
        this.grid2.data = this.$_.reject(this.grid2.data, item)
      })
      this.$refs['userTable2'].selected = [];
    },
  }
};
</script>
